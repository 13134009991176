<template>
  <div class="detail">
    <div class="detail__section primary-bg">
      <div class="container">
        <Loader v-if="isLoading" />
        <div
          v-else
          class="detail__inner flex f-space-between"
          v-scrollanimation
        >
          <div class="detail__left">
            <h2 class="detail__title headline-7 w700 left-to-right">
              {{ detail?.title }}
            </h2>
            <div class="detail__tags flex flex-wrap left-to-right delay-1">
              <div
                class="detail__tag"
                v-for="tag in detail?.tags"
                :key="tag.id"
              >
                {{ tag.title }}
              </div>
            </div>
            <div class="detail__features bottom-to-top delay-2">
              <vue-markdown
                :source="detail?.description"
                class="markdown-body"
              />
            </div>
          </div>
          <div
            v-if="detail?.long_picture?.large"
            class="detail__right flex f-justify-end right-to-left delay-5"
          >
            <img
              class="detail__image"
              :src="detail?.long_picture?.large"
              alt="banner"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="detail__section primary-bg">
      <Portfolio isOther isGrid />
    </div>
  </div>
</template>
<script setup>
import Portfolio from "@/components/Portfolio.vue";
import Loader from "../components/Loader.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { watch, computed, ref } from "vue";
import VueMarkdown from "vue-markdown-render";

const route = useRoute();
const store = useStore();

const isLoading = ref(true);

watch(
  () => route.params.slug,
  () => {
    store.dispatch("getDetail", {
      cb: () => {
        isLoading.value = false;
      },
      slug: route.params.slug,
    });
  },
  { immediate: true }
);

const detail = computed(() => store.getters.detail);
</script>
<style lang="scss" scoped>
.container {
  max-width: 1640px;
}

.markdown-body {
  background: transparent;
}

.detail {
  &__title {
    margin-bottom: 1.88rem;
  }

  &__inner {
    padding: 4.38rem 0;
    padding-bottom: 0;
    gap: 2rem;
  }

  &__tag {
    width: max-content;
    background: var(--bg-3);
    border-radius: 1.25rem;
    padding: 0.25rem 0.75rem;
    margin-bottom: 3.13rem;
  }

  &__tags {
    gap: 1.25rem;
  }

  &__features {
    gap: 1.25rem;
    margin-bottom: 1.88rem;

    h4 {
      margin-bottom: 0.5rem;
      font-size: 1.25em;
      line-height: 1.5rem;
      font-weight: 700;
    }

    p {
      line-height: 2rem;
      font-weight: 400;
      font-size: 1em;
      line-height: 1.5em;
    }
  }

  &__left {
    width: 50%;
  }

  &__num {
    min-width: 3.75rem;
    border: 0.06rem solid var(--gray-2);
    border-radius: 0.63rem;
    color: var(--primary-2);
  }

  &__right {
    position: relative;
    width: 50%;
  }

  &__image {
    width: 100%;
    height: auto;
    border-radius: 0.63rem;
    position: sticky;
    top: 1rem;
    align-self: flex-start;
  }
}

@media screen and (max-width: 1336px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 1024px) {
  .detail {
    &__inner {
      flex-direction: column-reverse;
    }

    &__left {
      width: 100%;
      max-width: 100%;
    }

    &__right {
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>
